import { login } from '@/api/xxlJob/jobTaskList'

// 登录
export function xxlLogin(callback) {
    login({userName:'admin',password:'123456',ifRemember:'on'}).then(res => {
        // 获取当前时间
        localStorage.setItem('xxlLoginTime',new Date().getTime())
        callback()
    })
}
// 判断是否需要登录
export function checkLogin(callback){
    let isNeedLogin;
    let xxlLoginTime = localStorage.getItem('xxlLoginTime')
    let localTime = new Date().getTime()
    var curTime = new Date(parseInt(xxlLoginTime))
    let localTimeFour = curTime.setHours(curTime.getHours() + 4)
    // xxlJob未登录 或者 已经超过四小时 则 需要登录
    if(!xxlLoginTime || localTime >= localTimeFour){
        isNeedLogin = true
        xxlLogin(callback)
    }else{
        isNeedLogin = false
        callback()
    }
}


