<template>
  <div>
    <el-container>
    <CommonTree
      :treeData="treeData"
      :defaultProps="defaultProps"
      searchTitle="title"
      :isShowdig="true"
      :showCheckbox="false"
      :popover-show="true"
      :treeTitle="$t(`cip.plat.xxljob.task.title.actator`)"
      @getTreeAdd="treeMenuAdd"
      @getTreeEdit="treeMenuEdit"
      @getTreeDelete="treeNodeDel"
      @getNodeClick="treeNodeClick"
      @include-down="includeDown"
    />
    <el-main>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t(`cip.plat.xxljob.task.title.indexHeadTitle`)"
          @head-remove="headRemove"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>
    <!-- 搜索条件 -->
    <grid-head-layout
      ref="gridHeadLayout"
      :grid-head-btn="gridHeadBtn"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <!-- table表格 -->
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
      @commandClick="commandClick"
    >
    </grid-layout>
    </el-main>
    <CommonDialog v-if="firstDialog" width="50%" :dialogTitle="$t(`cip.plat.xxljob.task.title.executeOnceHeadTitle`)" @cancel="firstDialog = false" @confirm="rowFirst">
      <el-form :model="firstForm" label-width="80px">
        <el-form-item :label="$t(`cip.plat.xxljob.task.field.executorParam`)" >
          <el-input type="textarea" v-model="firstForm.executorParam"></el-input>
        </el-form-item>
      </el-form>
    </CommonDialog>
      <CommonDialog v-if="showTreeDialog" class="qmDialog" :modal="false" width="60%" :dialogTitle="$t(`cip.plat.intf.interface.title.headCateTitle`)" @cancel="showTreeDialog = false" @confirm="handleTreeNodeSave">
        <avue-form :option="treeDialogOption" ref="addForm" v-model="form" @submit="treeNodeSave"></avue-form>
      </CommonDialog>
     <CommonDialog v-if="registerDialog" :showBtn="false" width="30%" :dialogTitle="$t(`cip.plat.xxljob.task.title.nodeHeadTitle`)" @cancel="registerDialog = false">
      <div v-for="(item,index) of registryList" :key="index">
        <tag>{{item}}</tag>
      </div>
    </CommonDialog>
    <CommonDialog v-if="nextDialog" width="30%" :showBtn="false" :dialogTitle="$t(`cip.plat.xxljob.task.title.nextHeadTitle`)" @cancel="nextDialog = false">
     <div v-for="(item,index) of nextList" :key="index">
        <tag>{{item}}</tag>
      </div>
    </CommonDialog>
    </el-container>
  </div>
</template>

<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import { mapGetters } from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import CommonTree from "@/views/components/com_tree/index";
import { getDetail, pageList as getList} from '@/api/xxlJob/jobActuatorList'
import { pageList, remove, trigger, nextTriggerTime, start, stop ,tree ,addCate, updateCate, deleteCate} from '@/api/xxlJob/jobTaskList'
import { checkLogin } from '@/util/xxljob'
import CommonDialog from "@/components/CommonDialog";
export default {
  name: "unit",
  components: {
    GridLayout,
    HeadLayout,
    CommonTree,
    CommonDialog
  },
  data() {
    return {
      tableLoading: true,
      tableData: [],
      treeData: [],
      form:{},
      showTreeDialog: false,
      dataTotal: null,
      firstDialog: false,
      registerDialog: false,
      nextDialog: false,
      registryList: [],
      nextList: [],
      firstForm:{},
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      node: {},
      cateId: '',
      defaultProps: {
        label: "name",
        value: "id",
        key: "id"
      },
    };
  },
  computed: {
    treeDialogOption() {
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 120,
        column: [{
          label: '上级分类',//this.$t('cip.plat.intf.interface.field.name'),
          prop: 'parentId',
          type: 'tree',
          labelWidth: 130,
          dicUrl: '/api/sinoma-xxljob-admin/jobInfoCate/tree',
          props: {
            label: 'name',
            value: 'id'
          },
          span: 12,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.intf.interface.field.name`),
        }, {
          label: this.$t('cip.plat.intf.interface.field.name'),
          prop: 'name',
          labelWidth: 130,
          span: 12,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.intf.interface.field.name`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t(`cip.plat.intf.interface.field.name`),
              trigger: "blur"
            },
            {
              pattern: /^\S.*\S$|(^\S{0,1}\S$)/,
              message: this.$t("cip.cmn.rule.deleteWarning"),
            }

          ],
        },
          {
            label: this.$t('cip.plat.intf.interface.field.code'),
            prop: 'code',
            span: 12,
            dataType: "string",
            placeholder: this.$t(`cip.plat.intf.interface.field.code`),
            rules: [
              {
                required: true,
                message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.intf.interface.field.code'),
                trigger: "blur"
              },
            ],
          },
        ]
      }
    },
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          label: "",
          display: false,
          prop: "jobGroup",
          type: "select",
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.xxljob.task.field.jobGroup"),
          clearable:false,
          // dicUrl: "/api/sinoma-system/dict/dictionary?code=oss",
          // props: {
          //   label: "dictValue",
          //   value: "dictKey"
          // },
          dicData:[],
          props: {
            label: "title",
            value: "id"
          },
        },
        {
          label: "",
          prop: "triggerStatus",
          type: "select",
          span:4,
          placeholder: this.$t("cip.cmn.rule.selectWarning") + this.$t("cip.plat.xxljob.task.field.triggerStatus"),
          clearable:false,
          // dicData: [{
          //   label: this.$t("cip.plat.xxljob.task.field.all"),
          //   value: -1
          // }, {
          //   label: this.$t("cip.plat.xxljob.task.field.stopIt"),
          //   value: 0
          // }, {
          //   label: this.$t("cip.plat.xxljob.task.field.initiate"),
          //   value: 1
          // }]
          dataType: 'number',
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_initiate",
        },
        {
          prop: "jobDesc",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.xxljob.task.field.jobDesc"),
        },
        {
          prop: "executorHandler",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.xxljob.task.field.glueType"),
        },
        {
          prop: "author",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.xxljob.task.field.author"),
        },
      ]
    },
    tableOptions() {
      return {
        selection:false,
        height: 0,
        menuWidth:150,
        linklabel: "taskIndex",
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.xxljob.task.field.id'),
            prop: 'id',
            overHidden: true,
            width:80,
          },
          {
            label: this.$t('cip.plat.xxljob.task.field.jobDesc'),
            prop: 'jobDesc',
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.xxljob.task.field.jobGroup'),
            prop: 'jobGroup',
            dicData:[],
            props: {
              label: "title",
              value: "id"
            },
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.xxljob.task.field.glueType'),
            prop: 'glueType',
            overHidden: true,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=GLUE_TYPE",
          },
          {
            label: this.$t('cip.plat.xxljob.task.field.jobCron'),
            prop: 'jobCron',
            align: "center",
            formslot:true,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.xxljob.task.field.author'),
            prop: 'author',
            overHidden: true
          },
          {
            label: this.$t('cip.plat.xxljob.task.field.triggerStatus'),
            prop: 'triggerStatus',
            type:'select',
            align: "center",
            // dicData:[{
            //   label: this.$t('cip.plat.xxljob.task.field.stopIt'),
            //   value:0,
            // },{
            //   label: this.$t('cip.plat.xxljob.task.field.initiate'),
            //   value:1
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_initiate",
          },
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      //新增按钮
      if (this.permission.task_edit) {
        result.push({
          label: this.$t('cip.cmn.btn.editBtn'),
          emit: "row-edit",
          type: "button",
          icon: ""
        });
      }

      if (this.permission.task_delete) {
        result.push({
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "row-remove",
          type: "button",
          icon: ""
        });
      }
      let btn = [];
      if (this.permission.task_start) {
        btn.push({
          label: this.$t('cip.plat.xxljob.task.btn.startBtn'),
          emit: "row-start",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.task_stop) {
        btn.push({
          label: this.$t('cip.plat.xxljob.task.btn.stopBtn'),
          emit: "row-stop",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.task_first) {
        btn.push({
          label: this.$t('cip.plat.xxljob.task.btn.firstBtn'),
          emit: "row-first",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.task_log) {
        btn.push({
          label: this.$t('cip.plat.xxljob.task.btn.logBtn'),
          emit: "row-log",
          type: "primary",
          icon: "el-icon-plus",
        });
      }

      if (this.permission.task_register) {
        btn.push({
          label: this.$t('cip.plat.xxljob.task.btn.registerBtn'),
          emit: "row-register",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
      if (this.permission.task_next) {
        btn.push({
          label: this.$t('cip.plat.xxljob.task.btn.nextBtn'),
          emit: "row-next",
          type: "primary",
          icon: "el-icon-plus",
        });
      }
        result.push({
          label: this.$t('cip.plat.xxljob.task.btn.moreBtn'),
          emit: "row-more",
          type: "primary",
          icon: "el-icon-plus",
          dropList:btn
        });
      return result;
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      //新增按钮
      if (this.permission.task_add) {
        result.push({
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: 'add',
        });
      }
      return result
    },
  },
  mounted() {
    this.$refs.gridHeadLayout.searchForm.triggerStatus = "-1"
    this.getData()
    this.getActatorData()
    this.initTree()
  },
  methods: {
    // 新增左侧树节点
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    treeNodeSave(data, done) {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let add = data.id ? updateCate : addCate
          add(this.form).then(res => {
            const {msg, data} = res.data;
            this.treeReadOnlyFlag = true;
            this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
            this.initTree();
            this.showTreeDialog = false;
          }).catch(err => {
            done()
          })
        }
      })
    },
    // 左侧树编辑
    treeMenuEdit(data) {
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
      this.form = data;
    },
    // 删除左侧树节点
    treeNodeDel(data, done) {
      if (data.children.length>0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.hasChildrenWarning'))
        return
      }
      deleteCate(data).then(res => {
        if(res.data.code == '200'){
          this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
          this.initTree()
        }
      })
    },
    // 左侧树新增
    treeMenuAdd() {
      this.form = {};
      this.form.parentId = this.node.id;
      this.treeReadOnlyFlag = false;
      this.fullscreen = false;
      this.showTreeDialog = true;
    },
    initTree() {
      tree().then(res => {
        let obj = {name: this.$t('cip.dc.QTemplate.field.whole'), id: ''}
        this.treeData = res.data;
        if (this.treeData.length > 0) this.treeData.unshift(obj)
      });
    },
    // 点击左侧树节点
    treeNodeClick(node) {
      this.node = node;
      const {id} = node;
      this.cateId = id;
      this.page.total = 0;
      this.onLoad(this.page);
    },
    includeDown(e) {
      const params = {};
      this.page.currentPage = 1;
      params.isInclude = e;
      this.onLoad(this.page,params);
    },
    getActatorData(){
      let searchForm = {
        appname:"",
        title:""
      }
      let start = 1
      let length = 20
      getList(start,length, Object.assign(searchForm)).then(res =>{
        const column = this.findObject(this.searchColumns, "jobGroup");
        const tableColumn = this.findObject(this.tableOptions.column, "jobGroup")
        column.dicData = res.data.data
        tableColumn.dicData = res.data.data
      })
    },
    rowFirst(){
      trigger(this.firstForm.id,this.firstForm.executorParam).then(res => {
        this.firstDialog = false
      })
    },
    handleFirst(row){
      this.firstDialog = true
      this.firstForm.id = row.id
    },
    handleLog(row){
      this.$router.push({
        path: `/xxlJob/dispatch/index`,
        query: {
          taskId: row.id,
        }
      })
    },
    handleRegister(row){
      getDetail(row.jobGroup).then(res=>{
        this.registerDialog = true
        this.registryList = res.data.content.registryList;
      })
    },
    handleNext(row){
      nextTriggerTime(row.jobCron).then(res => {
        this.nextDialog = true
        this.nextList = res.data.content;
      })
    },
    handleStart(row){
      this.$confirm(this.$t('cip.plat.xxljob.task.msg.start'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          start(row.id).then(() => {
            this.onLoad({pageSize: 20, currentPage: 1}, this.$refs.gridHeadLayout.searchForm)
            this.$message({
              type: "success",
              message: this.$t('cip.cmn.msg.success.operateSuccess'),
            });
          });
        })
    },
    handleStop(row){
      this.$confirm(this.$t('cip.plat.xxljob.task.msg.stop'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      }).then(() => {
        stop(row.id).then(() => {
          this.onLoad({pageSize: 20, currentPage: 1}, this.$refs.gridHeadLayout.searchForm)
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess'),
          });
        });
      })
    },
    // 更多内的点击事件
    commandClick(data) {
      if (data.command.emit == 'row-first') {
        this.handleFirst(data.row)
      } else if (data.command.emit == 'row-log') {
        this.handleLog(data.row)
      } else if (data.command.emit == 'row-register') {
        this.handleRegister(data.row)
      } else if (data.command.emit == 'row-next') {
        this.handleNext(data.row)
      }else if (data.command.emit == 'row-start') {
        this.handleStart(data.row)
      }else if (data.command.emit == 'row-stop') {
        this.handleStop(data.row)
      }
    },
    getData() {
      this.onLoad({pageSize: 20, currentPage: 1},this.$refs.gridHeadLayout.searchForm);
    },
    headRemove() {
      let selectionList = this.$refs.gridLayOut.selectionList
      let ids = selectionList.filter(e => e.id).map(e => e.id)
      if (selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          if (ids.length === 0) {
            //只选中了空数据
            let index = 0;
            selectionList.filter(e => !e.id).forEach(e => {
              this.$refs.gridLayOut.tableData.splice(e.$index - index, 1);
              index++
            })
            this.$refs.gridLayOut.selectionClear()
            return;
          }
          return remove(this.$func.join(ids))
            .then(() => {
              this.onLoad({pageSize: 20, currentPage: 1},this.$refs.gridHeadLayout.searchForm);
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
              this.$refs.gridLayOut.selectionClear()
            });
        }).catch(() => {
      })
    },
    headAddTabs() {
      this.$router.push({
        path: `/xxlJob/task/add`,
        query: {
          node: this.node,
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowDetail(row) {
      this.$router.push({
        path: `/xxlJob/task/edit`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad({pageSize: 20, currentPage: 1},this.$refs.gridHeadLayout.searchForm);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    rowEdit(row) {
      this.$router.push({
        path: `/xxlJob/task/edit`,
        query: {
          row: row,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },
    gridHeadSearch(query) {
      console.log("口模型是从*--------gridHeadSearch-------------",query)
      this.onLoad({pageSize: 20, currentPage: 1}, query)
    },
    gridHeadEmpty() {
      this.$refs.gridHeadLayout.searchForm.triggerStatus = "-1"
      this.onLoad({pageSize: 20, currentPage: 1})
    },
    onLoad(page, params = {}) {
      let that = this
      checkLogin(function(){
        that.page = page;
        that.tableLoading = true;
        // let searchForm = JSON.stringify(that.$refs.gridHeadLayout.searchForm)
        // if(searchForm=='{}'){
        //   searchForm = {
        //     "jobGroup":-1,
        //     "triggerStatus":-1}
        // }
        let searchForm={}
        searchForm.jobGroup = that.jobGroup ? that.jobGroup : -1
        searchForm.cateId = that.cateId ? that.cateId : -1
        searchForm.triggerStatus = that.$refs.gridHeadLayout.searchForm.triggerStatus?that.$refs.gridHeadLayout.searchForm.triggerStatus:-2
        console.log("进行输出------------------",searchForm)
        pageList(page.currentPage, page.pageSize, Object.assign(searchForm,params)).then(res => {
          const data = res.data;
          that.$refs.gridLayOut.page.total = data.recordsTotal;
          that.tableData = data.data;
          that.tableLoading = false;
        });
      })
    }
  }
};
</script>
<style>
</style>
